import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'
import http from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Swal from 'sweetalert2'

export default function useTestReportsList() {
  // Use toast
  const toast = useToast()

  const refSavedListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'student',
      label: 'Cтудент',
      sortable: true,
    },
    {
      key: 'date',
      label: 'Дата сдача',
      sortable: true,
    },
    {
      key: 'status',
      label: 'Статус',
      sortable: true,
    },
    {
      key: 'course',
      label: 'Для курса',
      sortable: true,
    },
    {
      key: 'module',
      label: 'Для модула',
      sortable: true,
    },
    {
      key: 'instructor',
      label: 'Ментор',
      sortable: true,
    },
    {
      key: 'action',
      label: 'Действии',
      sortable: false,
    },
  ]
  const perPage = ref(10)
  const totalSaveds = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('title')
  const isSortDirDesc = ref(true)
  const exportLink = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = refSavedListTable.value ? refSavedListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalSaveds.value,
    }
  })

  const refetchData = () => {
    refSavedListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchSaveds = (ctx, callback) => {
    http
      .post('https://edu.startupchoikhona.tj/backend/api/report/test', {
        search: searchQuery.value,
        length: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      })
      .then(response => {
        const config = {
          // example url
          url: 'https://edu.startupchoikhona.tj/backend/api/report/test/export',
          method: 'POST',
          responseType: 'blob',
          data: {
            search: searchQuery.value,
            length: perPage.value,
            page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
          },
        }
        http(config)
          .then(responseExport => {
            const reader = new FileReader()
            reader.readAsDataURL(responseExport.data)
            reader.onload = () => {
              exportLink.value = reader.result
            }
          })
        const saveds = response.data.data.items
        callback(saveds)
        totalSaveds.value = saveds.length
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Ошибка выборки список',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    exportLink,
    fetchSaveds,
    tableColumns,
    perPage,
    currentPage,
    totalSaveds,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refSavedListTable,
    refetchData,
  }
}
